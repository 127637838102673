@import '../../../../commons/styles/variables';

.pre-advice-status-import-error-status {
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  img {
    width: 17px;
  }
}
