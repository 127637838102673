@import 'bourbon', '../../commons/styles/variables', '../../commons/styles/icons';

.damage-report {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: 20px;
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: $color-background-gray;
  transition: opacity $base-animation-time;

  &-header {
    display: flex;
    width: 100%;
    flex-flow: row wrap-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 0 20px;
    overflow: hidden;

    &-actions {
      display: flex;

      button.btn {
        margin: 0 10px 0 0;
        white-space: nowrap;

        &-deactivate {
          min-width: 110px;
          background-position: 5% 50%;
          background-image: url('../../commons/assets/images/deactivate.svg');

          &:hover:not(:disabled) {
            background-color: rgba($color-danger, 0.1);
          }
        }

        &-new-repair {
          padding: 0 0 0 36px;
          min-width: 110px;
          background-position: 0 45%;
          background-image: icon(repairs, $color-dark);
          background-size: 32px 24px;

          &:hover:not(:disabled) {
            background-color: rgba($color-accent, 0.1);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .title {
    margin: 0;
    flex: 1;
    line-height: 44px;
    white-space: nowrap;
  }

  .subtitle {
    position: relative;
    margin: 20px 0 10px;

    .subtitle-link {
      font-size: 85%;
      cursor: pointer;
      color: $color-accent;
    }
  }

  @media (max-width: $device-width-tablet - 1px) {
    .title {
      flex-basis: 100%;
    }
  }
}
