@import 'icons';

button {
  border: 0;
  outline: none;
  background-color: transparent;
  appearance: none;
  &::-moz-focus-inner {
    border: 0;
  }
}

.btn {
  min-width: 100px;
  height: $base-button-height;
  line-height: $base-button-height;
  margin: 0 5px;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
  border-radius: $base-border-radius;

  &:disabled {
    cursor: default;
  }

  /**
  * Button of file choosing
  *
  */
  &-file {
    position: relative;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  /**
  * Button ghost
  *
  */
  &-ghost {
    color: $color-accent;
    background-color: #fff;
    border: 1px solid rgba($color-accent, 0.3);
    &:hover {
      background-color: rgba($color-accent, 0.1);
    }
    &:active {
      background-color: rgba($color-accent, 0.15);
    }
  }

  /**
  * Button link
  *
  */
  &-link {
    border: 0;
    margin: 0;
    display: block;
    background-color: transparent;
    color: $color-accent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    &.remove {
      color: $color-danger;
    }
  }

  /**
  * Button with icon
  *
  */
  &-icon {
    text-align: left;
    margin: 0;
    padding: 0 0 0 30px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 0 50%;

    &.btn-success,
    &.btn-accent,
    &.btn-neutral,
    &.btn-basic {
      padding-left: 40px;
      padding-right: 10px;
      background-position-x: 10px;
    }

    &.template {
      background-image: icon(template, rgba($color-accent, 0.99));
    }
    &.engine {
      background-image: icon(engine, rgba($color-accent, 0.99));
    }
    &.wagon-empty {
      background-image: icon(wagon-empty, rgba($color-accent, 0.99));
    }
    &.inverse {
      background-image: icon(inverse, rgba($color-accent, 0.99));
    }
    &.pdf {
      background-image: icon(pdf, rgba($color-accent, 0.99));
    }
    &.xls {
      background-image: icon(xls, rgba($color-accent, 0.99));
    }
    &.csv {
      background-image: icon(csv, rgba($color-accent, 0.99));
    }
    &.plus {
      background-image: icon(plus, rgba($color-accent, 0.99));
    }
    &.lock {
      background-image: icon(locked, rgba($color-accent, 0.99));
    }
    &.refresh {
      background-image: icon(refresh, rgba($color-accent, 0.99));
    }
    &.filter {
      background-image: icon(filter, rgba($color-accent, 0.99));
    }
    &.filter-reset {
      background-image: icon(filter-reset, rgba($color-accent, 0.99));
    }
    &.preadvice {
      background-image: icon(preadvice, rgba($color-accent, 0.99));
    }

    &.unlock {
      color: $color-neutral;
      background-image: icon(unlocked, rgba($color-neutral, 0.99));
    }

    &.remove {
      color: $color-danger;
      background-image: icon(remove, rgba($color-danger, 0.99));
    }

    &.add {
      background-image: icon(plus, inherit);
    }

    &.reset {
      color: $color-danger;
      background-image: icon(reset, rgba($color-danger, 0.99));
    }

    &.btn-basic {
      &.wagon-empty {
        background-image: icon(wagon-empty, rgba($color-dark, 0.7));
      }
      &.engine {
        background-image: icon(engine, rgba($color-dark, 0.7));
      }
      &.preadvice {
        background-image: icon(preadvice, rgba($color-dark, 0.7));
      }
    }

    &.btn-accent {
      &.lock {
        background-image: icon(locked, rgba($color-state-empty, 0.7));
      }
    }

    &.hyperlink {
      margin-top: -10px !important;
      align-self: end;
      line-height: 20px;
      padding: 0 0 0 24px;
      background-image: icon(hyperlink, rgba($color-accent, 0.99), transparent, 0, '', 24);
    }
  }

  /**
  * Button green
  *
  */
  &-success {
    color: #fff;
    background-color: $color-success;
    &:hover {
      background-color: darken($color-success, 4);
    }
    &:active {
      background-color: darken($color-success, 8);
    }
    &:disabled,
    &.disabled {
      background-color: $color-button-disabled;
    }
  }

  /**
  * Button accent
  *
  */
  &-accent {
    color: #fff;
    background-color: $color-accent;
    &:hover {
      background-color: darken($color-accent, 4);
    }
    &:active {
      background-color: darken($color-accent, 8);
    }
    &:disabled,
    &.disabled {
      background-color: $color-button-disabled;
    }
  }

  /**
  * Button neutral
  *
  */
  &-neutral {
    color: #fff;
    background-color: $color-neutral;
    &:hover {
      background-color: darken($color-neutral, 4);
    }
    &:active {
      background-color: darken($color-neutral, 8);
    }
    &:disabled,
    &.disabled {
      background-color: $color-button-disabled;
    }
  }

  /**
  * Button basic
  *
  */
  &-basic {
    color: rgba($color-dark, 0.7);
    background-color: rgba($color-dark, 0.05);
    &:hover {
      background-color: rgba($color-accent, 0.1);
    }
    &:active {
      background-color: rgba($color-accent, 0.15);
    }
    &:disabled {
      background-color: rgba($color-dark, 0.05);
      opacity: 50%;
    }
  }

  /**
  * Full width button
  *
  */
  &-full-width {
    width: 100%;
    margin: 0;
    border-radius: 0;
    height: $button-large-height;
    line-height: $button-large-height;
    font-size: 85%;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
  }

  /**
  * Round button
  */
  &-round {
    padding: 0;
    margin: 0;
    width: $base-button-height;
    min-width: $base-button-height;
    height: $base-button-height;
    border-radius: calc($base-button-height / 2);
    background-size: calc($base-button-height / 2) calc($base-button-height / 2);
    background: rgba($color-dark, 0.05) 50% 50% no-repeat;

    // Hide text
    font-size: 0;

    &:hover {
      background-color: rgba($color-accent, 0.1);
    }
    &:active {
      background-color: rgba($color-accent, 0.15);
    }
    &:disabled {
      background-color: rgba($color-dark, 0.05);
      opacity: 50%;
    }

    &.btn-success {
      background-color: $color-success;
      &:hover {
        background-color: darken($color-success, 4);
      }
      &:active {
        background-color: darken($color-success, 8);
      }
      &:disabled {
        background-color: $color-button-disabled;
      }
    }

    &.edit {
      background-image: icon(edit, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(edit, rgba($color-accent, 0.99));
      }
    }

    &.view {
      background-image: icon(view, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(view, rgba($color-accent, 0.99));
      }
    }

    &.plus {
      background-image: icon(plus, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(plus, rgba($color-accent, 0.99));
      }
      &.btn-success {
        background-image: icon(plus, rgba(255, 255, 255, 0.85));
        &:hover:not(:disabled) {
          background-image: icon(plus, rgba(255, 255, 255, 0.99));
        }
      }
    }

    &.pdf {
      background-image: icon(pdf, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(pdf, rgba($color-accent, 0.99));
      }
    }

    &.menu {
      background-image: icon(menu, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(menu, rgba($color-accent, 0.99));
      }
    }

    &.refresh {
      background-image: icon(refresh, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(refresh, rgba($color-accent, 0.99));
      }
    }

    &.filter {
      background-image: icon(filter, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(filter, rgba($color-accent, 0.99));
      }
    }

    &.xls {
      background-image: icon(xls, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(xls, rgba($color-accent, 0.99));
      }
    }

    &.csv {
      background-image: icon(csv, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(csv, rgba($color-accent, 0.99));
      }
    }

    &.checkmark {
      background-image: icon(checkmark, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(checkmark, rgba($color-accent, 0.99));
      }
    }

    &.template {
      background-image: icon(template, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(template, rgba($color-accent, 0.99));
      }
    }

    &.engine {
      background-image: icon(engine, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(engine, rgba($color-accent, 0.99));
      }
    }

    &.engine-plus {
      background-image: icon(engine-plus, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(engine-plus, rgba($color-accent, 0.99));
      }
    }

    &.wagon-empty {
      background-image: icon(wagon-empty, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(wagon-empty, rgba($color-accent, 0.99));
      }
    }

    &.wagon-plus {
      background-image: icon(wagon-plus, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(wagon-plus, rgba($color-accent, 0.99));
      }
    }

    &.inverse {
      background-image: icon(inverse, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(inverse, rgba($color-accent, 0.99));
      }
    }

    &.remove {
      background-image: icon(remove, rgba($color-dark, 0.7));
      &.danger {
        background-image: icon(remove, rgba($color-danger, 0.7));
      }
      &:hover:not(:disabled) {
        background-image: icon(remove, rgba($color-danger, 0.99));
        background-color: rgba($color-danger, 0.1);
      }
      &:active:not(:disabled) {
        background-color: rgba($color-danger, 0.15);
      }
    }

    &.reset {
      background-image: icon(reset, rgba($color-danger, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(reset, rgba($color-danger, 0.99));
        background-color: rgba($color-danger, 0.1);
      }
      &:active:not(:disabled) {
        background-image: icon(reset, rgba($color-danger, 0.99));
        background-color: rgba($color-danger, 0.15);
      }
    }

    &.close {
      background-image: icon(close, rgba($color-dark, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(close, rgba($color-danger, 0.99));
        background-color: rgba($color-danger, 0.1);
      }
      &:active:not(:disabled) {
        background-color: rgba($color-danger, 0.15);
      }
    }

    &.unlock {
      background-image: icon(unlocked, rgba($color-neutral, 0.7));
      &:hover:not(:disabled) {
        background-image: icon(unlocked, rgba($color-neutral, 0.99));
        background-color: rgba($color-neutral, 0.1);
      }
      &:active:not(:disabled) {
        background-color: rgba($color-neutral, 0.15);
      }
    }

    &.small {
      min-width: 32px;
      width: 32px;
      height: 32px;
      border-radius: 18px;
      background-size: 16px 16px;
    }
  }
}
