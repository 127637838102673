@import '../../../commons/styles/variables', '../../../commons/styles/icons';

.wagon-template-form-actions {
  .btn {
    font-size: 0.9em;
  }
}

.overlay-vehicle-library .checkbox {
  margin-bottom: 8px;
}

.overlay-vehicle-library {
  .round-btn-group {
    position: absolute;
    top: 15px;
    right: 16px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .btn-new-damage-report {
    background-image: icon(damage-reports, rgba($color-dark, 0.7), 'transparent', 0, '', 18);

    &:hover {
      background-image: icon(damage-reports, rgba($color-accent, 0.99));
    }
  }

  .btn-new-repair {
    padding: 0 0 0 36px;
    background-position: 4px 8px;
    background-size: 32px 24px;
    background-image: icon(repairs, rgba($color-dark, 0.7));

    &:hover:not(:disabled) {
      background-image: icon(repairs, rgba($color-accent, 0.99));
    }
  }
}
