@import '../../../node_modules/bourbon', '../../commons/styles/variables', '../../commons/styles/icons',
  '../../commons/styles/offline';

.table-repairs {
  .table-body-row {
    font-size: 90%;

    &.row-done {
      background-color: $color-state-validated;
    }
  }

  .cell-repair-number,
  .cell-damage-report-number,
  .cell-intervention_request_date,
  .cell-start-date,
  .cell-end-date,
  .cell-duration,
  .cell-status,
  .cell-market-name,
  .cell-market-axis,
  .cell-workshop-name,
  .cell-accident-number {
    flex: 1;
    margin-right: 16px;
  }

  .cell-wagon-registration-number {
    flex: 2;
  }
}
