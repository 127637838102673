@import 'bourbon', '../../../../commons/styles/variables';

.vehicle-damages {
  display: flex;
  gap: 4px;
  flex-flow: row wrap;

  .severe {
    color: $color-danger;
  }
}
