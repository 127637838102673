.overlay-form {
  .workshop-and-request-date {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;

    > div {
      width: 50%;
    }
  }

  div.repair-dates {
    display: flex;
    justify-content: space-between;

    div.input.input-label {
      flex-basis: 48.5%;
    }
  }
}
