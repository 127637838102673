@import '../../commons/styles/variables';
.topbar {
  .xls {
    margin-left: 8px;
  }
}

.filters {
  .date-range-filters {
    .label {
      margin-top: 10px;
      font-size: 85%;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: calc($base-button-height / 2);
      line-height: calc($base-button-height / 2);
      user-select: text;
    }

    .date-interval {
      display: flex;
      margin: 0 0 10px 0;
    }
  }
}
