@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

div.form-section {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: $base-border-radius;
  border: 1px solid $base-border-color;
  background-color: #fff;
  position: relative;

  .title {
    cursor: unset;
  }

  &.clickable {
    cursor: pointer;
  }

  > .btn.collapse {
    position: absolute;
    left: 4px;
    top: 8px;
    margin: 0;
    background-image: icon(arrow-down, rgba($color-dark, 0.7));
    transition-property: transform, background-color;
    transition-duration: calc($base-animation-time);

    &:not(:hover):not(:active) {
      background-color: rgba($color-accent, 0.02);
    }

    &.collapsed {
      transform: rotate(-90deg);
    }

    & + .title {
      margin-left: 30px;
    }
  }

  &.collapsible > .content {
    overflow: hidden;
    transition-property: height, background-color;
    transition-duration: calc($base-animation-time);
  }

  .fields {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 0;
    column-gap: 10px;

    &.two-columns {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-between;

      .input,
      .dropdown,
      .column {
        flex: 45% 3;
      }

      .input.rdt {
        flex: 100% 6;
      }
    }

    .input {
      flex: 30% 2;
      max-width: 100%;
    }

    .input.rdt {
      flex: 100% 6;
    }

    .dropdown {
      flex: 30% 2;
      margin: 10px 0;
    }

    .full-width {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;

      .input {
        flex: 100% 6;
      }
    }

    .full-width-input {
      width: 100%;

      .input {
        flex: 100% 6;
      }
    }

    .loaded {
      width: 32%;
      padding-top: 35px;

      .checkbox {
        align-content: center;
      }
    }

    .label-select {
      width: 32%;

      .input {
        width: 100%;
      }
    }

    .checkbox {
      padding: 0 0 0 27px;
      margin: 0 23px 0 0;
      align-content: center;
    }

    @media (max-width: $device-width-tablet - 1px) {
      div.input,
      div.dropdown,
      div.input-checkboxes {
        flex: 45% 3;
        margin: 5px 0 0;
      }
    }
  }
}
