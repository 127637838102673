@import '../../../../commons/styles/variables';

.external-damage-report-form-modal {
  .modal-body {
    max-height: 90%;
    font-size: 100%;
    overflow: scroll;
    text-align: left;
  }

  .btn {
    font-size: 0.9em;
  }

  .rdtPicker {
    top: -305px;
  }
  .input {
    margin: 0 !important;
  }
  .error-message {
    text-align: start;
  }

  .form-section {
    border: none;
    padding: 0;
    margin: 0;
  }

  .damages {
    label {
      text-align: start;
    }
    .damage-report-form-damage {
      padding-top: 10px;
    }

    li {
      background-color: $color-background-gray;
    }
    .btn {
      background-color: white;
    }
    margin-bottom: 0;
  }
}
