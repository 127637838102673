@import '../../../commons/styles/variables';

.form-section {
  padding-top: 4px !important;
}

.wagon-damage-report-details {
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    text-align: left;
    padding: 4px 0 4px 0;
    margin-right: 8px;
    border: 1px solid;
    padding-inline: 8px;
  }

  .severe {
    color: $color-danger;
  }

  .damage-label-ONE {
    color: $color-danger;
  }

  .damage-report-number,
  .damage-report-validation-date {
    width: fit-content;
  }

  .external-damage-report {
    font-style: italic;
  }
}
