@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.table-vehicles-library {
  .table-header {
    column-gap: 8px;
    padding: 0 15px;
  }

  .table-body {
    overflow-x: hidden;
  }

  .table-body-row {
    padding: 15px;
    white-space: nowrap;
    column-gap: 8px;

    &.row-engine {
      cursor: default;
      color: $color-text-gray;

      .registration-cell {
        background-image: icon('engine', $color-gray);
      }
    }

    &.row-wagon {
      .registration-cell {
        background-image: icon('wagon-empty', rgba($color-black, 0.99));
      }
    }

    .registration-cell {
      background-position: 0px 30%;
      background-repeat: no-repeat;

      span {
        font-size: 90%;
        font-weight: bold;
        text-transform: uppercase;
        padding-left: 30px;
      }
    }

    &.loader {
      position: relative;
    }

    @media (max-width: $device-width-tablet - 1px) {
      padding-left: 8px;
      padding-right: 8px;
    }

    [class^='cell-'] {
      font-size: 90%;
    }
  }

  .registration-cell {
    min-width: 150px;
    flex: 1.5;
    white-space: nowrap;
  }

  .cell-owner {
    min-width: 170px;
    flex: 1.7;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .sub-cell-number-serie {
    width: 100%;
    font-weight: normal;
    font-size: 0.8em;
    margin-right: 3%;
    white-space: nowrap;
    padding-left: 0.4%;
    padding-top: 0.4%;
  }

  .cell-params {
    min-width: 240px;
    flex: 2;

    .value {
      width: 75px;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;

      &.value-length {
        width: 65px;
      }

      &.value-tare-engine {
        width: 40px;
      }

      &.value-mode {
        width: 70px;
      }
    }
  }

  .cell-company {
    min-width: 100px;
    flex: 1;
  }

  .cell-wagon-labels,
  .cell-wagon-damages {
    min-width: 100px;
    flex: 2;
  }

  .cell-wagon-template-status {
    color: $color-neutral;
  }
}
