@import 'bourbon', '../../../../commons/styles/variables', '../../../../commons/styles/icons';

.vehicle-edit {
  flex: 1;
  height: 100%; // ICPO-510 Safari bug
  background-color: #fff;
  overflow: hidden;

  &-form {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.loader {
      position: static;
    }

    &.loader::after {
      margin-left: calc($vehicles-min-width / 2) - 17px;
    }

    > form {
      padding: 0 20px 20px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      @media (max-width: $device-width-tablet - 1px) {
        padding: 0 10px;
      }
    }
  }

  &-header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 15px 20px;

    gap: 10px;

    > * {
      margin: 0;
    }

    .title-group {
      flex: 1;
      display: flex;
      align-items: center;

      .title {
        margin-right: 16px;
      }

      @media (max-width: $device-width-tablet - 1px) {
        .title {
          display: none;
        }
      }
    }

    .loader {
      width: 34px;
    }

    > .btn.btn-icon.wagon-empty {
      min-width: unset;
      padding-left: 10px;
      padding-right: 40px;
      //noinspection CssInvalidPropertyValue
      background-position-x: right 10px;
    }

    @media (max-width: $device-width-tablet - 1px) {
      padding: 10px 10px 20px;

      .switch2-label {
        position: absolute;
        top: 100%;
        left: -13px;
        padding: 0;
        width: 94px;
        height: 24px;
        line-height: 24px;
        text-align: center;
      }

      .wagon-edit & .title {
        opacity: 0;
      }
    }
  }

  .fields {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    column-gap: 10px;
    flex-grow: 1;
    align-content: flex-start;

    .segment-button {
      width: 100%;
    }

    .input,
    .dropdown,
    .input-checkboxes {
      flex: 30% 2;
    }

    .two-cols {
      flex: 60% 4;
    }

    .input-checkboxes {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .checkbox {
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: 40px;
      }
    }

    .inline-button {
      margin-top: 20px;

      .btn {
        margin: 0;
      }
    }

    @media (max-width: $device-width-tablet - 1px) {
      div.input,
      div.dropdown,
      div.input-checkboxes {
        flex: 45% 3;
        margin: 5px 0 0;
      }
      .two-cols {
        flex: 100% 6;
      }
    }
  }

  &-back {
    height: $base-button-height + 17px;
    background: $color-dark;

    .btn {
      font-size: 80%;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: bold;
      width: $vehicles-min-width - 20px;
      margin: 7px 10px 10px;
      background-image: icon(arrow-left, rgba(255, 255, 255, 0.8));
      background-repeat: no-repeat;
      background-position: 10px 50%;
      background-color: rgba($color-accent, 0.5);

      &:hover {
        background-color: rgba($color-accent, 0.4);
      }

      &:active {
        background-color: rgba($color-accent, 0.3);
      }
    }
  }
}

@keyframes vehicle-edit-on {
  0% {
    opacity: 0;
    transform: (translate(50%, 0));
  }

  100% {
    opacity: 1;
    transform: (translate(0, 0));
  }
}
