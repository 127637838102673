@import '../../../../commons/styles/variables';

[class$='vehicle-labels'] {
  div {
    padding: 0 6px;
    height: 18px;
    width: 34px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 60%;
    font-weight: 900;
    border-radius: calc($base-border-radius / 2);
    background-color: rgba($color-dark, 0.1);
  }

  .wagon-label-K {
    background-color: #33bbff;
  }

  .wagon-label-I {
    border: solid #dddddd 0.2px;
    color: black;
    background-color: #ffff00;
  }

  .wagon-label-M,
  .wagon-label-U,
  .wagon-label-IN,
  .wagon-label-IS,
  .wagon-label-R_ONE {
    border: solid #dddddd 0.2px;
    color: black;
    background-color: white;
  }

  .wagon-label-TWO {
    background-color: #8a2be2;
  }

  .wagon-label-ONE,
  .wagon-label-ZERO {
    background-color: $color-danger;
  }
}

.left-panel-vehicle-labels {
  position: absolute;
  bottom: 7px;
  right: 7px;
  display: flex;
  gap: 8px;
  flex-direction: row-reverse;
}

.details-vehicle-labels {
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: row-reverse;

  @media (max-width: $device-width-tablet - 1px) {
    [class^='wagon-label-'] {
      width: fit-content;
    }
  }
}

.list-vehicle-labels {
  display: flex;
  gap: 2px;
  flex-direction: row;
}
